const frontendUrl = 'https://auditnexpoc.contiinex.com';
const backendUrl = 'https://auditnexpoc.contiinex.com';

// const frontendUrl = 'http://localhost:4200';
// const backendUrl = 'http://localhost:8000';

const coachnexSocketUrl = 'wss://comnexdev.contiinex.com/media'; // 'ws://localhost:8020'
const insightUrl = 'https://auditnexpoc.contiinex.com';
// const supersetUrl = 'https://kslsuperset.contiinex.com';
// const dashboardUrl = 'https://ksldashboard.contiinex.com';

// const insightUrl = 'https://auditnexdashboard.contiinex.com';
const dashboardUrl = 'https://auditnexpocdashboard.contiinex.com';

export const environment = {
  title: 'AuditNex',
  production: false,
  frontendUrl: frontendUrl,
  backendUrl: backendUrl,
  apiUrl: `${backendUrl}/api`,
  socketUrl: backendUrl,
  coachnexSocketUrl: coachnexSocketUrl,
  insightUrl: insightUrl,
  superset: {
    url: dashboardUrl,
    username: 'admin',
    password: 'admin',
    processes: {
      1: {
        key: '843a67ca-cc69-40a1-b03e-afedf21b2709',
        dashboard: 1
        },
      2: {
        key: '843a67ca-cc69-40a1-b03e-afedf21b2709',
        dashboard: 1
      },
      3: {
        key: '843a67ca-cc69-40a1-b03e-afedf21b2709',
        dashboard: 1
      },
      4: {
        key: '368d0a38-3d24-4996-bb42-48d083bbf9c2',
        dashboard: 2
      },
      5: {
        key: '0176814d-f871-4d5a-bbe7-133885f3e626',
        dashboard: 3
      },
      6: {
        key: 'a52ca879-fb78-4169-8ff2-d225f11044cb',
        dashboard: 4
      },
      7: {
        key: 'd81996a6-f9fc-468a-be92-de02d628cf76',
        dashboard: 5
      },
      8: {
        key: 'e158e468-ea1b-40d4-880d-489b38736537',
        dashboard: 7
      }
    }
  },
  featureConfig: {
    process: {
      2: {
        showMultiLanguageTranscriptOption: true,
        multiLanguageTranscriptOption1: 3,
        multiLanguageTranscriptOption2: 8,
        multiLanguageTranscriptOption3: 4,
        defaultMultiLanguageTranscriptOption: 3,
        disableSandbox: false,
        disableReAudit: false
      },
      5: {
        showMultiLanguageTranscriptOption: true,
        multiLanguageTranscriptOption1: 3,
        multiLanguageTranscriptOption2: 8,
        multiLanguageTranscriptOption3: 4,
        defaultMultiLanguageTranscriptOption: 3,
        disableSandbox: true,
        disableReAudit: true,
	      enableMetadata: true
      },
      4: {
        disableSandbox: true,
        disableReAudit: true
      },
      6: {
        disableSandbox: true,
        disableReAudit: true
      },
      7: {
	      disableSandbox: true,
        disableReAudit: true,
	      showSummaryView: true
      }
	
    },
    domainWise: {
      audit: {
        isDisableSocketFunctionality: true,
        disableSandbox: true,
        disableReAudit: true
      },
      localhost: {
        isDisableSocketFunctionality: true,
        // disableSandbox: true,
        disableReAudit: false,
        isDisableNotification: false
      },
      auditnexpoc: {
        disableSandbox: false,
        disableReAudit: false,
        isShowNewTemplateBtn: true
      }
    }
  },
  okta: {
    clientId: `0oadk0sw7uaWxsC6y5d7`,
    issuer: `https://dev-64266215.okta.com/oauth2/default`,
    redirectUri: `${frontendUrl}/auth/okta/callback`,
    logoutUrl: `${frontendUrl}/auth/login`,
    scopes: ['openid', 'profile', 'email', 'address', 'phone', 'userType'],
  },
  loginAllow: ['contiinex', 'okta'],
};
